::-webkit-scrollbar {
    width: 7px; /* Width of the scrollbar */
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background color of the scrollbar track */
  }
  
  ::-webkit-scrollbar-thumb {
    background: #c7c7c7; /* Background color of the scrollbar thumb */
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #c7c7c7; /* Background color of the scrollbar thumb on hover */
  }

  .blinking-cursor {
    width: 2px; /* Adjust the width of the cursor */
    height: 20px; /* Adjust the height of the cursor */
    animation: blink 1.1s infinite; /* Blink animation */
  }

  path {
    /* Your default SVG path styles */
    transition: fill 0.2s ease-in; /* Define transition property for fill attribute */
  }
  
  @keyframes blink {
    0% {
      opacity: 1; /* Cursor visible */
    }
    50% {
      opacity: 0; /* Cursor invisible */
    }
    100% {
      opacity: 1; /* Cursor visible */
    }
  }

  .section-2 {
    position: relative;
  }

  .timeline-container {
    position: absolute;
    bottom: -60%;
    background-color: #fff;
    z-index: 1;
  }

  @media screen and (max-width: 1065px){
    .timeline-container {
      bottom: -70%;
      max-width: 90%;
    }
  }

  /* @media screen and (max-width: 1030px){
    .timeline-container {
      bottom: -70%;
      max-width: 90%;
    }
  } */

  @media screen and (max-width: 900px){
    .timeline-container {
      bottom: -80%;
      max-width: 90%;
    }
  }

  @media screen and (max-width: 768px){
    .timeline-container {
      bottom: -50%;
      max-width: 90%;
    }
  }

  @media screen and (max-width: 695px){
    .timeline-container {
      bottom: -60%;
      max-width: 90%;
    }
  }

  @media screen and (max-width: 575px){
    .timeline-container {
      bottom: -70%;
      max-width: 90%;
    }
    .timeline-text-title {
      font-size: 0.7rem !important;
      padding: 0.2rem;
    }
    .timeline-text-body {
      font-size: 0.6rem !important;
      padding: 0.2rem;
    }
  }

  .section-3 {
    /* background-image: url('./backgrounds/bg-1.png'); */
    /* background-size: cover; */
    background-color: #f8f8f8;
  }

  .project-title {
    margin-top: 30vh;
  }

  .mt-40 {
    margin-top: 40vh;
  }

  .project-container {
    background-color: #4f20d2;
  }

.carousel-container {
  width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative;
}

.carousel {
  width: 100%;
  height: 100%;
}

.carousel img {
  width: 100%;
  height: auto;
  transition: transform 0.5s ease;
}

.carousel img:not(:first-child) {
  transform: translateY(100%);
}
